import React, {useState} from 'react';
import './style.css';
import {Button, Card, Col, Row} from "react-bootstrap";
import axios from "axios";
import {toast} from "react-toastify";

// import {useCookies} from "react-cookie";
import { saveAs } from 'file-saver';
import DeleteConfirmation from "./DeleteConfirmation";
// import {useEffect} from "@types/react";

const OrderCard = (props) => {
    // console.log(props)
    let datetime = new Date(props.delivery_time);
    const baseURL = process.env.REACT_APP_API_URL;
    const [rest_id, setRest_id] = useState(localStorage.getItem('rest_id'));

    const [idToReject, setIdToReject] = useState(null);
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false);
    const [rejectMessage, setRejectMessage] = useState('')

    function showRejectModal(){
      setIdToReject('Rejected');
      setRejectMessage(`Ben je zeker dat je bestelling '${props.daily_order_number}' wilt annuleren?`);
      setDisplayConfirmationModal(true);
    }

    const hideRejectModal = () => {
      setDisplayConfirmationModal(false);
    };

    // const [cookies, setCookie, removeCookie] = useCookies(['toPrint']);

    async function update_status_order(newStatus){
        // console.log(baseURL+'/restaurant/orderstatus/update/'+props.daily_order_number)
        // console.log({params:{order_number: props.order_number.toString(), tableid: props.tableid.toString(), order_status: newStatus}})
        await axios.post(baseURL+'/restaurant/orderstatus/update/'+props.order_id,{order_id: props.order_id, tableid: props.tableid, order_status: newStatus})

      .then((response) => {
        props.update_orders()
      })
      .catch((error) => {
        toast.error('Unable to update status', { containerId: 'B' });
      });
    }


    async function print_order(){
        const baseURL = process.env.REACT_APP_API_URL

    await axios.get(baseURL+'/restaurant/'+rest_id+'/order/'+props.order_id)
      .then((response) => {
        // console.log(response.data.data);
        // axios.post("http://0.0.0.0:5000", response.data.data)
        //   console.log("print")
        props.setPrinted(props.printed.concat(response.data.data.orders[0]['order_id']))
        // console.log(document.getElementById( "printButton_"+props.order_number))
        document.getElementById( "printButton_"+props.order_id).style.color = "grey"
        document.getElementById( "printButton_"+props.order_id).style.borderColor = "grey"

        let info =  response.data.data.orders[0]
          // console.log(info)
        let datetime = new Date(info.delivery_time)
        const day = String(datetime.getDate()).padStart(2, '0');
        const month = String(datetime.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = datetime.getFullYear();
        const hour = String(datetime.getHours()).padStart(2, '0');
        const minute = String(datetime.getMinutes()).padStart(2, '0');

        info.delivery_time = `${day}-${month}-${year} ${hour}:${minute}`;

        datetime = new Date(info.ordertime)
        const orderday = String(datetime.getDate()).padStart(2, '0');
        const ordermonth = String(datetime.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const orderyear = datetime.getFullYear();
        const orderhour = String(datetime.getHours()).padStart(2, '0');
        const orderminute = String(datetime.getMinutes()).padStart(2, '0');

        info.ordertime = `${orderday}-${ordermonth}-${orderyear} ${orderhour}:${orderminute}`;


          if (info.tableid !== -1){

              info.order_type = "Tafel " + info.tableid
          }

          console.log(info)
          axios.post(baseURL+'/restaurant/printorder/', info)

      })
      .catch((error) => {
        console.log("in catch", error)
        toast.error('Unable to print order', { containerId: 'B' });
      });

    }
  return (
      <>
            <Card id={props.id} className="card-stats orderCard" onClick={props.onClick}>
              <Card.Body>
                <Row>
                    <Col xs="5">
                      <p>{props.daily_order_number}</p>
                    </Col>
                </Row>
                <Row>
                  <Col xs="5">
                      <p>{props.customer_name}</p>
                  </Col>
                  <Col xs="7">
                      <p>€{(parseFloat(props.total_amount))?.toFixed(2) ?? 0.0}</p>
                  </Col>
                </Row>
                <Row>
                    <Col xs="5">
                      <p>Besteld voor: </p>
                    </Col>
                    <Col xs="7">
                        <div>
                            {datetime.toLocaleTimeString(navigator.language, {
                              day: '2-digit',
                              month: 'short',
                              hour: '2-digit',
                              minute: '2-digit',
                            })}

                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs="5">
                      <p>Type: </p>
                    </Col>
                    <Col xs="7">
                        {props.order_type}
                    </Col>
                </Row>
                <Row>
                    <Col xs="5">
                      <p>Commentaar: </p>
                    </Col>
                    <Col xs="7">
                        {props.user_note}
                    </Col>
                </Row>
                <Row>
                    <div className="col text-center">
                        {props.status === "Pending" &&
                            <Button className="btn btn-danger" type="button" onClick={(e) => showRejectModal()}>
                                Annuleer
                            </Button>
                        }

                        {((props.status === "Accepted" || props.status === "Pending") && props.printed.some(v => (v === props.order_id))) &&

                            < Button id={"printButton_"+props.order_id} className="btn btn-info" type="button" style={{color: "grey", borderColor: "grey"}} onClick={(e) => print_order()}>
                            Print
                            </Button>
                        }
                        {((props.status === "Accepted" || props.status === "Pending") && ! props.printed.some(v => (v === props.order_id))) &&
                            < Button id={"printButton_"+props.order_id} className="btn btn-info" type="button" onClick={(e) => print_order()}>
                            Print
                            </Button>
                        }
                        {props.status === "Pending" &&
                            <Button className="btn btn-success" type="button" onClick={(e) => update_status_order("Accepted")}>
                                Accepteer
                            </Button>
                        }
                    </div>
                </Row>

              </Card.Body>

            </Card>
      <DeleteConfirmation showModal={displayConfirmationModal} confirmModal={update_status_order} hideModal={hideRejectModal} id={"Rejected"} message={rejectMessage} />
</>
  );
}

export default OrderCard;



